<!--
 * @Author: LXG
 * @Date: 2021-05-21
 * @Editors: LXG
 * @LastEditTime: 2021-05-21
 * @Description: 企业合规 - 操作弹窗
-->
<template>
    <el-dialog
        class="start-dialog"
        :visible.sync="show"
        width="1024px"
        title="启动合规"
        :show-close="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        @closed="closedDialog"
    >
        <div>
            <el-form
                ref="form"
                :model="formData"
                size="medium"
                label-width="130px"
            >
                <div class="form-module">
                    <h3 class="module-title">合规企业信息</h3>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="企业名称">
                                <FormInput v-model="formData.companyInfo.name"></FormInput>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="统一社会信用代码">
                                <FormInput v-model="formData.companyInfo.tyshxydm"></FormInput>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="法定代表人">
                                <FormInput v-model="formData.companyInfo.frdb"></FormInput>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="法人身份证号码">
                                <FormInput v-model="formData.companyInfo.frIdNumber"></FormInput>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="合规专员">
                                <FormInput v-model="formData.companyInfo.hgzy"></FormInput>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="专员身份证号码">
                                <FormInput v-model="formData.companyInfo.zyIdNumber"></FormInput>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="注册地址">
                                <FormInput v-model="formData.companyInfo.address"></FormInput>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="联系电话">
                                <FormInput v-model="formData.companyInfo.phone"></FormInput>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div class="form-module">
                    <h3 class="module-title">合规信息</h3>
                    <el-row>
                        <el-form-item label="合规类型">
                            <FormRadioGroup
                                v-model="formData.complianceInfo.type"
                                :options="complianceTypeList"
                            ></FormRadioGroup>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item label="案情事由">
                            <FormCheckboxGroup
                                v-model="formData.complianceInfo.reason"
                                :options="complianceReasonList"
                            ></FormCheckboxGroup>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item label="案情摘要">
                            <FormTextarea v-model="formData.complianceInfo.abstract"></FormTextarea>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item label="合规说明">
                            <FormTextarea v-model="formData.complianceInfo.description"></FormTextarea>
                        </el-form-item>
                    </el-row>
                </div>
                <div class="form-module">
                    <h3 class="module-title">办理进度</h3>
                    <el-table
                        style="margin-bottom:20px;"
                        :data="progressData"
                        stripe
                        border
                        size="medium"
                    >
                        <!-- <el-table-column
                            type="index"
                            label="序号"
                            width="50px"
                            align="center"
                        ></el-table-column> -->
                        <el-table-column
                            prop="node"
                            label="环节名称"
                            align="center"
                        ></el-table-column>
                        <el-table-column
                            prop="processTime"
                            label="办理时间"
                            align="center"
                        ></el-table-column>
                        <el-table-column
                            prop="processer"
                            label="办理人"
                            align="center"
                        ></el-table-column>
                        <el-table-column
                            prop="processOpinion"
                            label="办理意见"
                            align="center"
                        ></el-table-column>
                        <el-table-column
                            prop="createTime"
                            label="发起时间"
                            align="center"
                        ></el-table-column>
                        <el-table-column
                            prop="processMaterial"
                            label="办理材料"
                            align="center"
                        ></el-table-column>
                    </el-table>
                </div>
                <div class="form-module">
                    <h3 class="module-title">合规专员信息</h3>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="合规专员">
                                <FormInput v-model="formData.officerInfo.name"></FormInput>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="身份证号码">
                                <FormInput v-model="formData.officerInfo.zyIdNumber"></FormInput>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="联系电话">
                                <FormInput v-model="formData.officerInfo.phone"></FormInput>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div class="form-module">
                    <h3 class="module-title">确认独立监控人</h3>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="候选独立监控人">
                                <FormSelect
                                    v-model="formData.monitorInfo.monitorPeople"
                                    :options="monitorPeopleList"
                                ></FormSelect>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="监控人名称">
                                <FormInput v-model="formData.monitorInfo.monitorPeopleName"></FormInput>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="统一社会信用代码">
                                <FormInput v-model="formData.monitorInfo.tyshxydm"></FormInput>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="注册地址">
                                <FormInput v-model="formData.monitorInfo.address"></FormInput>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="法定代表人">
                                <FormInput v-model="formData.monitorInfo.frdb"></FormInput>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="专员身份证号码">
                                <FormInput v-model="formData.monitorInfo.frIdNumber"></FormInput>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div class="form-module">
                    <h3 class="module-title">材料信息</h3>
                    <el-table
                        style="margin-bottom:20px;"
                        :data="materialData"
                        stripe
                        border
                        size="medium"
                    >
                        <!-- <el-table-column
                            type="index"
                            label="序号"
                            width="50px"
                            align="center"
                        ></el-table-column> -->
                        <el-table-column
                            prop="materialName"
                            label="材料名称"
                            align="center"
                        ></el-table-column>
                        <el-table-column
                            prop="fileName"
                            label="附件名称"
                            align="center"
                        ></el-table-column>
                        <el-table-column
                            prop="fileSize"
                            label="附件大小"
                            align="center"
                        ></el-table-column>
                        <el-table-column
                            prop="uploader"
                            label="上传人"
                            align="center"
                        ></el-table-column>
                        <el-table-column
                            prop="createTime"
                            label="上传时间"
                            align="center"
                        ></el-table-column>
                        <el-table-column
                            prop="status"
                            label="状态"
                            align="center"
                        ></el-table-column>
                    </el-table>
                </div>
            </el-form>
        </div>
        <template v-slot:footer>
            <div>
                <el-button @click="close">关闭</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
import { FormInput, FormTextarea, FormSelect, FormRadioGroup, FormCheckboxGroup } from '@/components/form/index.js'

export default {
    name: 'StartDialog',
    components: {
        FormInput, FormTextarea, FormSelect, FormRadioGroup, FormCheckboxGroup,
    },
    data() {
        return {
            show: false,
            formData: {
                // 合规企业信息
                companyInfo: {
                    name: '欣旺达电子股份有限公司', // 企业名称
                    tyshxydm: '91440300279446850J', // 统一社会信用代码
                    frdb: '王威', // 法定代表人
                    frIdNumber: '', // 法人身份证号码
                    hgzy: '', // 合规专员
                    zyIdNumber: '', // 合规专员身份证号
                    address: '', // 注册地址
                    phone: '', // 联系电话
                },
                // 合规信息
                complianceInfo: {
                    type: '0', // 合规类型
                    reason: '', // 案情事由
                    abstract: '', // 案情摘要
                    description: '', // 合规说明
                },
                // 合规专员信息
                officerInfo: {
                    name: '', // 合规专员名称
                },
                // 独立监控人信息
                monitorInfo: {
                    monitorPeople: '', // 候选独立监控人
                    monitorPeopleName: '', // 监控人名称
                    tyshxydm: '', // 统一社会信用代码
                    address: '', // 注册地址
                    frdb: '', // 法定代表人
                    frIdNumber: '', // 法人身份证号码
                },
            },
            complianceTypeList: [
                { value: '0', label: '刑事合规' },
                { value: '1', label: '行政合规' },
            ],
            complianceReasonList: [
                { value: '0', label: '虚空增值税专用发票' },
            ],
            monitorPeopleList: [
                {
                    value: '0',
                    label: '赖勇智',
                }
            ],
            progressData: [], // 办理进度
            materialData: [], // 材料信息
        }
    },
    methods: {
        /**
         * @description: 显示并初始化dialog
         * @param {*}
         */
        initDialog() {
            this.show = true
        },
        /**
         * @description: 关闭dialog
         */
        close() {
            this.show = false
        },
        /**
         * @description: 关闭弹窗后
         */
        closedDialog() { },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
    max-height: 60vh;
    overflow-y: auto;
}
.module-title {
    position: relative;
    padding-left: 15px;
    margin-bottom: 10px;

    &::before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        width: 5px;
        border-radius: 4px;
        background-color: $col-theme;
    }
}
::v-deep .form-select {
    width: 100%;
}
</style>