<!--
 * @Author: LXG
 * @Date: 2021-05-21
 * @Editors: LXG
 * @LastEditTime: 2021-05-21
 * @Description: 企业合规 - 首页(目前仅写死数据，演示用)
-->
<template>
    <div class="compliance">
        <div class="def-container wrap-header">
            <div class="pad-2 company">
                <div class="company-header">
                    <div class="company-avatar_outer">
                        <span>{{cpu_firstWord}}</span>
                    </div>
                    <div class="header-right">
                        <h2 class="company-name">{{userInfo.enterpriseInfo.enterprise}}</h2>
                        <p class="company-level">帐户安全等级: Lv2</p>
                    </div>
                </div>
                <div class="company-info">
                    <p class="info-title">企业基本信息</p>
                    <div class="company-info-item">
                        <label>法定代表人</label>
                        <div>{{companyPortraitData.frdb}}</div>
                    </div>
                    <div class="company-info-item">
                        <label>统一信用代码</label>
                        <div>{{userInfo.tyshxydm}}</div>
                    </div>
                    <div class="company-info-item">
                        <label>注册地址</label>
                        <div
                            class="infoItem-value"
                            :title="companyPortraitData.zcdz"
                        >{{companyPortraitData.zcdz}}</div>
                    </div>
                </div>
            </div>
            <div class="header-total">
                <div
                    v-for="(item,index) in cpu_totalList"
                    :key="index"
                    class=" bord-r-2 header-total-item"
                    :style="{'background-color':item.bgColor}"
                >
                    <h2>{{item.label}}</h2>
                    <div class="totalItem-content">
                        <div class="totalItem-text">
                            <span>{{item.desc}}</span>
                            <p>
                                <span style="font-size:32px;font-weight:bold;">{{item.total}}</span>
                                件
                            </p>
                        </div>
                        <div>
                            <img
                                :src="item.icon"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="def-container wrap-main">
            <h3>业务列表</h3>
            <el-table :data="listData">
                <el-table-column
                    type="index"
                    label="序号"
                    width="50px"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="serialNumber"
                    label="合规编号"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="companyName"
                    label="合规企业"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="type"
                    label="合规类型"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="initiator"
                    label="发起人"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="createTime"
                    label="发起时间"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="currentNode"
                    label="当前环节"
                    align="center"
                ></el-table-column>
                <el-table-column
                    label="操作"
                    align="center"
                >
                    <el-button
                        size="medium"
                        type="primary"
                        @click="start"
                    >启动</el-button>
                </el-table-column>
            </el-table>
        </div>
        <StartDialog ref="startDialog"></StartDialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import StartDialog from './components/startDialog/StartDialog'
import variableStyle from '@/styles/variable.scss'

const totalIcons = [
    require('@/assets/images/compliance/faqihegui.png'),
    require('@/assets/images/compliance/daibanyewu.png'),
    require('@/assets/images/compliance/jianchaqi.png'),
    require('@/assets/images/compliance/anjianzongshu.png'),
    require('@/assets/images/compliance/jieanyewu.png'),
    require('@/assets/images/compliance/tuihuiyewu.png'),
]

export default {
    name: 'Compliance',
    components: {
        StartDialog,
    },
    data() {
        return {
            companyPortraitData: {}, // 企业画像基本信息
            totalInfo: {
                fqhgTotal: 12, // 发起合规
                dbywTotal: 12, // 待办业务
                jcqTotal: 12, // 监察期
                ajzsTotal: 12, // 案件总数
                jaywTotal: 12, // 结案业务
                thywTotal: 12, // 退回业务
            },
            listData: [
                {
                    serialNumber: '210414Z001X78',
                    companyName: 'XXXXXXXXXXXXX有限公司',
                    type: '被动',
                    initiator: '检察院-李建强',
                    createTime: '2021-04-15',
                    currentNode: '独立监控协议审核',
                }
            ],
        }
    },
    computed: {
        ...mapGetters({
            userInfo: 'getUserInfo',
        }),
        /**
         * @description: 企业名称首字
         */
        cpu_firstWord() {
            // 用于企业无LOGO时的显示
            return this.userInfo.enterpriseInfo.enterprise.replace(/^(深圳市|深圳)/g, '')[0]
        },
        cpu_totalList() {
            return [
                {
                    label: '发起合规',
                    desc: '合规业务',
                    bgColor: variableStyle['color-red'],
                    icon: totalIcons[0],
                    total: this.totalInfo.fqhgTotal
                },
                {
                    label: '待办业务',
                    desc: '待办业务',
                    bgColor: variableStyle['color-theme'],
                    icon: totalIcons[1],
                    total: this.totalInfo.dbywTotal
                },
                {
                    label: '监察期',
                    desc: '监察期业务',
                    bgColor: variableStyle['color-yellow'],
                    icon: totalIcons[2],
                    total: this.totalInfo.jcqTotal
                },
                {
                    label: '案件总数',
                    desc: '案件业务总数',
                    bgColor: variableStyle['color-green'],
                    icon: totalIcons[3],
                    total: this.totalInfo.ajzsTotal
                },
                {
                    label: '结案业务',
                    desc: '已结案业务',
                    bgColor: '#42E1B6',
                    icon: totalIcons[4],
                    total: this.totalInfo.jaywTotal
                },
                {
                    label: '退回业务',
                    desc: '退回业务',
                    bgColor: variableStyle['color-gay'],
                    icon: totalIcons[5],
                    total: this.totalInfo.thywTotal
                },
            ]
        },
    },
    methods: {
        /**
         * @description: 启动合规
         * @param {*}
         * @return {*}
         */
        start() {
            this.$refs.startDialog.initDialog()
        },
        /**
         * @description: 查询企业画像信息
         */
        getCompanyPortraitData() {
            let url = '/dev-api/sqzdManager/data/find/sq_qyhx_new_baseinfo_query'
            let params = {
                tyshxydm: this.userInfo.tyshxydm,
            }
            this.$httpApi.post(url, params).then(res => {
                console.log('getCompanyPortraitData:', res)
                if (res.data && !!res.data.length) {
                    this.companyPortraitData = res.data[0]
                }
            }).catch(err => {
                this.$message.error(`getCompanyPortraitData error:${err}`)
            })
        },
    },
    created() {
        console.log('created:', this.userInfo)
        this.getCompanyPortraitData()
    },
}
</script>

<style lang="scss" scoped>
$avatarSize: 64px;

.compliance {
    min-height: calc(100vh - #{$headerHeight} - #{$footerHeight});
    padding: 1rem;
    background-color: #f8f8f8;
}
.wrap-header {
    display: -moz-grid;
    display: -ms-grid;
    display: grid;
    grid-template-columns: 350px 1fr;
    grid-template-rows: 350px;
    gap: 1rem 1rem;
}
.company {
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffffff;

    .company-header {
        display: -webkit-flex;
        display: flex;

        .header-right {
            display: -webkit-flex;
            display: flex;
            -ms-flex-direction: column;
            flex-direction: column;
            justify-content: space-between;
            height: $avatarSize;
        }
    }
    .company-avatar_outer {
        width: $avatarSize;
        height: $avatarSize;
        border-radius: 4px;
        margin-right: 10px;
        line-height: $avatarSize;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        background-color: rgba($col-theme, 0.2);
    }
    .company-name {
        color: #333333;
    }
    .company-level {
        color: #999999;
    }
    .company-info {
        .info-title {
            margin-bottom: 10px;
            font-size: 16px;
            color: $col-theme;
        }
    }
    .company-info-item {
        display: -webkit-flex;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #eeeeee;
        line-height: 40px;
        color: #999999;

        .infoItem-value {
            flex: 1;
            padding-left: 20px;
            white-space: nowrap;
            -o-text-overflow: ellipsis;
            -ms-text-overflow: ellipsis;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}
.header-total {
    display: -moz-grid;
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px 0.5rem;
}
.header-total-item {
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 0 0 20px;
    color: #ffffff;

    .totalItem-content {
        display: -webkit-flex;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }
    .totalItem-text {
        transform: translateY(-16px);
    }
}
</style>